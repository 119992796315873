var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isProcessing === false)?_c('div',{staticClass:"container-fluid mt-5 mx-4"},[_c('h3',{staticClass:"h3 mx-3 my-2"},[_vm._v("Top 10 DSAs In Renewal")]),_c('div',{staticClass:"w-50 mx-0"},[_vm._m(0),_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"col"},[_c('date-picker',{staticClass:"w-100",attrs:{"valueType":"format","placeholder":"Date From"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('div',{staticClass:"col"},[_c('date-picker',{staticClass:"w-100",attrs:{"valueType":"format","placeholder":"Date To"},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('div',{staticClass:"col d-flex"},[_c('button',{staticClass:"my-auto p-2 mx-2 h4 rounded bg-default w-100",on:{"click":_vm.searchAction}},[_vm._v(" Search ")]),_c('button',{staticClass:"my-auto p-2 h4 rounded mx-2 bg-default w-100",on:{"click":_vm.resetAction}},[_vm._v(" Reset ")])])])]),(_vm.agents.length > 0)?_c('div',{staticClass:"mx-3 card"},[_c('table',{staticClass:"table table-striped table-bordered table-hover",staticStyle:{"font-size":"14px"}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.agents),function(agent,index){return _c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(agent.agent_name))]),_c('td',[_vm._v(_vm._s(agent.number_sales))]),_c('td',[_vm._v(_vm._s(agent.total_renewals))]),_c('td',[_c('div',{staticClass:"progress-container",class:_vm.getPercent(agent) >= 50
									? 'progress-success'
									: 'progress-primary'},[_c('span',{staticClass:"progress-badge font-weight-bold"},[_vm._v(_vm._s(_vm.getPercent(agent))+" %")]),_c('div',{staticClass:"progress",staticStyle:{"height":"8px"}},[_c('div',{staticClass:"progress-bar",style:({
										'width': _vm.getPercent(agent) + '%',
										'font-weight': '',
									}),attrs:{"role":"progressbar","aria-valuenow":_vm.getPercent(agent),"aria-valuemin":"0","aria-valuemax":"100"}})])])])])}),0)])]):_c('div',[_c('zero-state',{attrs:{"title":'No Agents List',"message":'There are currrently no agents to view'},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("../../../assets/thumb-up.png")}})]},proxy:true}],null,false,2246867572)})],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 mx-2"},[_c('h5',{staticClass:"col"},[_vm._v("Filter by Date")])])
}]

export { render, staticRenderFns }